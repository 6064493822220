
import "../style/footer.css"
import think from "../images/solution-thinking.svg"
import design from "../images/web-design-brush.svg"
import get from "../images/get.svg"

export default function Footer(){

    const list = [
        {
            img: think,
            hashtag: "#THINK",
            text: "Pensez protection des données, dès maintenant, dans l'ère du numérique"
        },
        {
            img: get,
            hashtag: "#GET",
            text: "Prenez une longueur d'avance bénéfique à tous les niveaux"
        },
        {
            img: design,
            hashtag: "#DESIGN",
            text: "Concevoir une conformité adaptée à vos besoins"
        }
    ]
    return (
        <div className="footer">
            <div className="headContent">
                <h1>DPO Design</h1>
                <h5>Votre partenaire en droit en matière de protection des données</h5>
            </div>
            <div className="mainContent">
                <h2>Découvrez notre démarche circulaire</h2>
                <div className="listOfPerks">
                    {
                        list.map( (item) => {
                            return(
                                <div className="perk">
                                    <img src={item.img} alt={item.hashtag} title={item.hashtag} />
                                    <h3>{item.hashtag}</h3>
                                    <h5>{item.text}</h5>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <h4>contact [at] dpodesign.fr</h4>
        </div>
    )
}