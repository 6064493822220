import './App.css';
import PartOne from "./components/PartOne";
import PartTwo from "./components/PartTwo";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
        <PartOne />
        <PartTwo/>
        <Footer/>
    </div>
  );
}

export default App;
