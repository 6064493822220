
import "../style/partTwo.css"
export default function PartTwo() {

    const listOfBox = [
        {
            titre: "Sensibilisation",
            text: "Découvrir et comprendre les enjeux en matière de protection des données"
        },
        {
            titre: "Etat des lieux",
            text: "Audit et diagnostic de vos missions vis-à-vis de la collecte de données"
        },
        {
            titre: "Registre",
            text: "Produire son registre des traitements",
        },
        {
            titre: "Agir et prioriser",
            text: "Engager une démarche de mise en conformité"
        },
        {
            titre: "Analyses d'impact",
            text: "Engager les analyses d'impact obligatoire"
        },
        {
            titre: "Accountability",
            text: "Être en mesure de démontrer le respect du RGPD",
        },
        {
            titre: "Formation",
            text: "Former vos collaborateurs avec un programme personnalisé",
        },
        {
            titre: "Pratiques professionnelles",
            text: "Comment protéger votre entité",
        },
        {
            titre: "Responsabilisation des acteurs",
            text: "Construire et vérifier votre sécurité juridique",
        },
        {
            titre: "Codes de conduite",
            text: "Créer des fiches et livrets sur la conduite à tenir en cas de contrôle, de violations de données, demande de droit des personnes...",
        },
        {
            titre: "Désigner un DPO",
            text: "Externaliser votre délégué à la protection des données, DPODESIGN est certifié AFNOR",
        },
        {
            titre: "Veille SSI et RGPD",
            text: "Obtenir l'ensemble des informations et référentiels sur un sujet spécifique",
        }
    ]

    return(

        <div className="partTwo">
            <div className="headTitle">
                <h1>Un accompagnement sur mesure</h1>
                <h4>Découvrez les briques de conformité adaptées à tous vos projets numériques :</h4>
            </div>
            <div className="listOfBox">
                {
                    listOfBox.map((el) => {
                        return(
                            <div className="boxOfCompliance">
                                <h3>{el.titre}</h3>
                                <p>{el.text}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}