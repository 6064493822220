import React, {useState} from "react"
import "../style/partOne.css"
import logo from "../images/img.png"
import ReactTypingEffect from "react-typing-effect"

export default function PartOne() {

    const [isOpened, setIsOpened] = useState(false)

    return(
        <div className="partOne">
            <div className="leftSide">
                <img src={logo} alt="logo dpodesign"/>
            </div>
            <div className="rightSide">
                <div className="switchingText">
                    <ReactTypingEffect text={["Ecouter", "Conseiller", "Accompagner"]} eraseDelay={500} typingDelay={500}/>
                </div>
                <div className="mainText">
                    <h4>Une expertise en matière de protection des données !</h4>
                    <h1>Lancez-vous !</h1>
                </div>
                <div className="contactButton">
                    <button onClick={() => setIsOpened(!isOpened)}>Contactez-nous</button>
                    {isOpened && <span className="infoBulle">contact[at]dpodesign.fr</span>}
                </div>
            </div>
        </div>
    )
}